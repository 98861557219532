import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";
import getNetworkSharingLinksInfo from "pages/Networks/Sidebar/NetworkSharingLinksInfo";

const NetworkSharingLinks = (props) => {
  const { network } = props;

  const sharingLinksInfo = useMemo(
    () => network && getNetworkSharingLinksInfo(network),
    [network]
  );
  const eventProps = useMemo(
    () => ({
      network_id: network.get("id"),
    }),
    [network]
  );

  return (
    <EntitySharingLinks
      {...props}
      entity={network}
      entity_type="network"
      info={sharingLinksInfo}
      eventProps={eventProps}
    />
  );
};

NetworkSharingLinks.propTypes = {
  network: PropTypes.instanceOf(Map),
  eventProps: PropTypes.object,
};
NetworkSharingLinks.defaultProps = {
  network: Map({}),
  eventProps: {},
};

export default memo(NetworkSharingLinks);
