import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import getPodcastUrl from "utils/entity/getPodcastUrl";

const PodcastSharingLinks = (props) => {
  const { podcast, eventProps: passedEventProps } = props;

  const { info, eventProps } = useMemo(
    () => ({
      info: {
        url: `${getBaseUrl()}${getPodcastUrl(podcast)}`,
        title: `${podcast.get("title")} on Podchaser`,
        twitterTitle: `${podcast.get("title")} on @Podchaser`,
        image_url: `${podcast.get("image_url")}`,
        twitterHandle:
          podcast.getIn([
            "settings",
            "additional_links",
            "social_links",
            "twitter",
          ]) || null,
      },
      eventProps: {
        podcast_id: podcast.get("id"),
        ...passedEventProps,
      },
    }),
    [passedEventProps, podcast]
  );

  return (
    <EntitySharingLinks
      {...props}
      entity={podcast}
      entity_type="podcast"
      info={info}
      eventProps={eventProps}
    />
  );
};

PodcastSharingLinks.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  eventProps: PropTypes.object,
};
PodcastSharingLinks.defaultProps = {
  podcast: Map({}),
  eventProps: {},
};

export default memo(PodcastSharingLinks);
