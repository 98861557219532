import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import { selectSpecificPodcast } from "selectors/podcast";
import { getRawEpisodeImageUrl } from "utils/entity/getEpisodeImageUrl";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";

import useReduxState from "hooks/useReduxState";

const EpisodeSharingLinks = (props) => {
  const {
    podcast: passedPodcast,
    episode,
    eventProps: passedEventProps,
  } = props;

  const loadedPodcast = useReduxState(
    (state) =>
      selectSpecificPodcast(
        state,
        passedPodcast
          ? null
          : episode.get("podcast") || episode.get("podcast_id")
      ),
    [passedPodcast, episode]
  );

  const podcast = passedPodcast || loadedPodcast;

  const { info, eventProps } = useMemo(
    () => ({
      eventProps: podcast &&
        episode && {
          podcast_id: podcast.get("id"),
          episode_id: episode.get("id"),
          ...passedEventProps,
        },
      info: podcast &&
        episode && {
          url: `${getBaseUrl()}${getEpisodeUrl(episode, { podcast })}`,
          title: `${episode.get("title")} on Podchaser`,
          twitterTitle: `${episode.get("title")} on @Podchaser`,
          image_url: `${episode.get("image_url")}`,
          twitterHandle:
            podcast.getIn([
              "settings",
              "additional_links",
              "social_links",
              "twitter",
            ]) || null,
          headlinerInfo: {
            title: `${episode.get("title")} on Podchaser`,
            audioUrl: episode.get("audio_url"),
            imageUrl: getRawEpisodeImageUrl(episode, podcast),
          },
        },
    }),
    [podcast, episode, passedEventProps]
  );

  if (!info || !eventProps) {
    return null;
  }

  return (
    <EntitySharingLinks
      {...props}
      entity={episode}
      entity_type="episode"
      info={info}
      eventProps={eventProps}
    />
  );
};

EpisodeSharingLinks.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  episode: PropTypes.instanceOf(Map).isRequired,
  eventProps: PropTypes.object,
};

EpisodeSharingLinks.defaultProps = {
  eventProps: {},
};

export default memo(EpisodeSharingLinks);
