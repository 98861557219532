import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { compose } from "redux";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";
import WhiteBtnWithIcon from "components/Buttons/Items/WhiteBtnWithIconAsync";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import EpisodeSharingLinks from "components/Podcast/Episode/EpisodeSharingLinks";
import PodcastSharingLinks from "components/Podcast/PodcastSharingLinks";
import ReviewSharingLinks from "components/Review/ReviewSharingLinks";
import UserBadgeSharingLinks from "components/Tastemaker/UserBadgeSharingLinks";
import UserListSharingLinks from "components/UserLists/UserListSharingLinks";
import UserSharingLinks from "components/Users/UserSharingLinks";
import CreatorSharingLinks from "pages/CreatorView/Sidebar/CreatorSharingLinks";

import NetworkSharingLinks from "../../Lists/Network/NetworkSharingLinks";

import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import withHover from "utils/react-with-hover";
import sendGAEvent from "utils/sendGAEvent";

import useActivityContext from "hooks/useActivityContext";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

const baseStyles = {
  smallShareButtonToolTip: {
    display: "block",
  },
  smallShareButton: {
    display: "block",
    fontSize: ".75rem",
    color: "#9da6b7",
    cursor: "pointer",
  },
};

const ShareButton = (props) => {
  const {
    actioned,
    showLabel,
    showLabelOnHover,
    renderSharingLinks: passedRenderSharingLinks,
    positionFixed,
    id,
    buttonLabel,
    buttonTooltip,
    renderButton: passedRenderButton,
    small,
    entity_type,
    entity,
    podcast,
    popoutProps,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const showPositionFixed = positionFixed || isWindowSizeOrLess("medium");

  const feedActivityContext = useActivityContext();

  const labels = useMemo(
    () => ({
      default: showLabel ? buttonLabel : null,
      hover: showLabelOnHover ? buttonLabel : null,
      actioned: showLabel ? buttonLabel : null,
      actionedHover: showLabelOnHover ? buttonLabel : null,
    }),
    [showLabel, showLabelOnHover, buttonLabel]
  );

  const handleToggle = useCallback(
    (toggleMenu, open) => (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!open) {
        sendGAEvent({
          ...(podcast
            ? {
                podcast_id: podcast.get("id"),
                podcast_name: podcast.get("title"),
              }
            : {}),
          entity_type,
          entity_id: entity?.get("id"),
          entity_name: entity?.get("title") || entity?.get("name"),
          ...feedActivityContext,
          context: "entity card",
          componentContext: "ShareButton",
          action: "shareModalOpen",
        });
      }

      toggleMenu();
    },
    [entity_type, entity, feedActivityContext, podcast]
  );

  const handleKeyDown = useCallback(
    (toggleMenu, open) => (e) => {
      if (eventIsFieldTrigger(e)) {
        e.preventDefault();
        handleToggle(toggleMenu, open)(e);
      }
    },
    [handleToggle]
  );

  const renderTooltip = useCallback(
    (contentProps) => <span {...contentProps}>{buttonTooltip}</span>,
    [buttonTooltip]
  );

  const renderTooltipContent = useCallback(
    // eslint-disable-next-line react/display-name
    ({ ref, toggleMenu, open }) =>
      (tooltipProps) =>
        (
          <span
            className={css(styles.smallShareButtonToolTip)}
            {...tooltipProps}
          >
            <a
              aria-label="Share Button"
              href="#"
              className={css(styles.smallShareButton)}
              ref={ref}
              onClick={handleToggle(toggleMenu, open)}
              onKeyDown={handleKeyDown(toggleMenu, open)}
              data-id="share_button"
            >
              <FontAwesomeIcon icon={faShareSquare} />
            </a>
          </span>
        ),
    [
      handleToggle,
      handleKeyDown,
      styles.smallShareButton,
      styles.smallShareButtonToolTip,
    ]
  );

  const renderSmallButton = useCallback(
    (popoverProps) => (
      <BasicTooltip renderTooltip={renderTooltip} hideOnTouchDevice>
        {renderTooltipContent(popoverProps)}
      </BasicTooltip>
    ),
    [renderTooltipContent, renderTooltip]
  );

  const renderButton = useCallback(
    ({ toggleMenu, ref, open }) => (
      <WhiteBtnWithIcon
        ref={ref}
        icon={faShareSquare}
        labels={labels}
        onClick={handleToggle(toggleMenu, open)}
        dataId="share_button"
        actioned={actioned}
        variation="white"
        ariaLabel="Share"
        flat
      />
    ),
    [labels, actioned, handleToggle]
  );

  const renderSharingLinks = useCallback(
    (downshiftProps) => {
      switch (entity_type) {
        case "creator":
          return (
            <CreatorSharingLinks
              creator={entity}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "episode":
          return (
            <EpisodeSharingLinks
              episode={entity}
              podcast={podcast}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "podcast":
          return (
            <PodcastSharingLinks
              podcast={entity}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "review":
          return (
            <ReviewSharingLinks
              review={entity}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "userlist":
          return (
            <UserListSharingLinks
              listId={entity.get("id")}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "user":
          return (
            <UserSharingLinks
              user={entity}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "badge":
          return (
            <UserBadgeSharingLinks
              entities={entity}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        case "network":
          return (
            <NetworkSharingLinks
              network={entity}
              eventProps={feedActivityContext}
              downshiftProps={downshiftProps}
            />
          );
        default:
          return null;
      }
    },
    [entity_type, entity, feedActivityContext, podcast]
  );

  return (
    <ButtonWithPopout
      id={id}
      renderButton={
        passedRenderButton || (small ? renderSmallButton : renderButton)
      }
      renderContent={passedRenderSharingLinks || renderSharingLinks}
      positionFixed={showPositionFixed}
      positionAbsolute={!showPositionFixed}
      ariaLabel="Share"
      offsetArrow
      small
      {...popoutProps}
    />
  );
};

ShareButton.propTypes = {
  actioned: PropTypes.bool,
  showLabel: PropTypes.bool,
  showLabelOnHover: PropTypes.bool,
  renderSharingLinks: PropTypes.func,
  positionFixed: PropTypes.bool,
  id: PropTypes.string,
  buttonLabel: PropTypes.node,
  buttonTooltip: PropTypes.node,
  renderButton: PropTypes.func,
  small: PropTypes.bool,
  entity_type: PropTypes.string,
  entity: PropTypes.oneOfType([
    PropTypes.instanceOf(Map),
    PropTypes.shape({
      user: PropTypes.instanceOf(Map),
      badge: PropTypes.instanceOf(Map),
    }),
  ]),
  podcast: PropTypes.instanceOf(Map),
  popoutProps: PropTypes.object,
};

ShareButton.defaultProps = {
  actioned: false,
  showLabel: false,
  showLabelOnHover: true,
  renderSharingLinks: null,
  positionFixed: false,
  id: null,
  buttonLabel: "Share",
  buttonTooltip: "Share",
  renderButton: null,
  small: false,
  entity_type: null,
  entity: null,
  podcast: null,
  popoutProps: {},
};

export default compose(withHover())(ShareButton);
