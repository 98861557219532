import { getBaseUrl } from "constants/base";
import { getNetworkUrlByEntity } from "utils/entity/getNetworkUrl";

function NetworkSharingLinksInfo(network) {
  if (!network) {
    return {};
  }

  return {
    url: `${getBaseUrl()}${getNetworkUrlByEntity(network)}`,
    title: `Podcasts of ${network?.get("title")} on Podchaser`,
    twitterTitle: `Podcasts of ${network?.get("title")} on Podchaser`,
    image_url: `${network?.get("image_url")}`,
  };
}

export default NetworkSharingLinksInfo;
