import getUserSlug from "./getUserSlug";

import { formatURL } from "utils/format";

const getEarnedUserBadgeURL = (user, badge) => {
  if (!badge) {
    return "";
  }

  const userUrl = `/users/${getUserSlug(user)}?badge_achieved=${badge.get(
    "code"
  )}`;

  return formatURL(userUrl);
};

export default getEarnedUserBadgeURL;
