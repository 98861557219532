import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import { getCreatorUrl } from "utils/url/creatorUrls";

const getInfo = (creator) => ({
  url: `${getBaseUrl()}${getCreatorUrl(creator)}`,
  title: `${creator.get("name")} on Podchaser`,
  twitterTitle: `${creator.get("name")} on @Podchaser`,
  image_url: `${creator.get("image_url")}`,
  twitterHandle: creator.get("twitter_handle") || null,
});

const CreatorSharingLinks = (props) => {
  const { creator } = props;
  const info = useMemo(() => getInfo(creator), [creator]);

  return (
    <EntitySharingLinks
      {...props}
      entity={creator}
      entity_type="creator"
      info={info}
      variation="white"
    />
  );
};

CreatorSharingLinks.propTypes = {
  creator: PropTypes.instanceOf(Map).isRequired,
};

export default CreatorSharingLinks;
