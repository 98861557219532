import { Map } from "immutable";
import PropTypes from "prop-types";
import { forwardRef, memo, useMemo } from "react";
import { useLocation } from "react-router-dom";

import EntitySharingLinks from "../Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import getEarnedUserBadgeURL from "utils/entity/getEarnedUserBadgeURL";

const UserBadgeSharingLinks = (props) => {
  const { entities, forwardedRef, eventProps: passedEventProps } = props;
  const { user, badge } = entities;

  const location = useLocation();
  const { pathname } = location;

  const isMyBadgesPage = pathname.includes("/profile/badges");

  const { info, eventProps } = useMemo(() => {
    const title = `${
      isMyBadgesPage ? "I've" : "I just"
    } unlocked the ${badge.get("title")} badge on @Podchaser!`;

    const innerInfo = {
      url: `${getBaseUrl()}${getEarnedUserBadgeURL(user, badge)}`,
      image_url: `${getBaseUrl()}${getEarnedUserBadgeURL(user, badge)}`,
      title,
      description: user.get("description"),
      twitterTitle: title,
    };
    const innerEventProps = {
      entity_id: user.get("id"),
      entity_type: "user_badge_earned",
      ...passedEventProps,
    };

    return {
      info: innerInfo,
      eventProps: innerEventProps,
    };
  }, [user, badge, passedEventProps, isMyBadgesPage]);

  return (
    <EntitySharingLinks
      {...props}
      entity={user}
      entity_type="user"
      info={info}
      eventProps={eventProps}
      ref={forwardedRef}
    />
  );
};

UserBadgeSharingLinks.propTypes = {
  entities: PropTypes.shape({
    user: PropTypes.instanceOf(Map),
    badge: PropTypes.instanceOf(Map),
  }),
  forwardedRef: PropTypes.object,
  eventProps: PropTypes.object,
};
UserBadgeSharingLinks.defaultProps = {
  entities: {},
  forwardedRef: null,
  eventProps: null,
};

const ForwardedWrapper = forwardRef((props, ref) => (
  <UserBadgeSharingLinks forwardedRef={ref} {...props} />
));

ForwardedWrapper.displayName = "UserBadgeSharingLinks";

export default memo(ForwardedWrapper);
